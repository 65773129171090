import { Center, Container, Heading } from "@chakra-ui/react";
import { graphql, PageProps } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import Card from "src/atoms/Card";
import ArticleGrid from "src/components/ArticleGrid";
import Layout from "src/components/Layout";
import Markdown from "src/components/Markdown";
import Pagination from "src/components/Pagination";
import StructuredText from "src/components/StructuredText";
import { PaginationContext } from "src/gatsby/node/lib/PaginationContext";
import getCategoryPath from "src/lib/getCategoryPath";
import getExcerpt from "src/lib/getExcerpt";

type CategoryProps = PageProps<
  Gatsby.CategoryQuery,
  PaginationContext & {
    model: "newsPage" | "eventsPage" | "publicationsPage";
    categoryPath: "news" | "events" | "publications";
  }
>;

const Category = ({ data, pageContext }: CategoryProps) => {
  const { title, body } = data[pageContext.model]!;

  const articlesAndArchives = pageContext.articlesAndArchives;

  return (
    <Layout>
      <Helmet
        meta={[
          { name: "description", content: getExcerpt(body, { length: 157 }) },
        ]}
        title={title}
      />

      <Container as="section" maxW="container.xl">
        <Card mb={6} size="lg">
          <Heading marginBottom={10}>{title}</Heading>

          <Markdown>
            <StructuredText data={body} />
          </Markdown>
        </Card>

        <ArticleGrid articles={articlesAndArchives} mb={12} />

        <Center>
          <Pagination
            currentPage={pageContext.currentPage}
            getPath={getCategoryPath(pageContext.categoryPath)}
            pageSize={data.datoCmsGlobal!.articlesPerPage!}
            totalItems={pageContext.totalItems}
          />
        </Center>
      </Container>
    </Layout>
  );
};

export default Category;

export const query = graphql`
  query Category { # ($articleType: String!, $skip: Int!, $limit: Int!)
    publicationsPage: datoCmsPublicationsPage {
      title
      body {
        value
      }
    }

    newsPage: datoCmsNewsPage {
      title
      body {
        value
      }
    }

    eventsPage: datoCmsEventsPage {
      title
      body {
        value
      }
    }

    # articles: allDatoCmsArticle(
    #   filter: { articleType: { eq: $articleType } }
    #   sort: { fields: publicationDate, order: DESC }
    #   skip: $skip
    #   limit: $limit
    # ) {
    #   nodes {
    #     ...Article
    #   }
    # }

    datoCmsGlobal {
      articlesPerPage
      logo {
        gatsbyImageData(width: 88)
      }
    }
  }
`;
